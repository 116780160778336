export const PAGINATION_LIMITS = {
  // surveys
  SURVEY_LIST: 1_000,
  // responses
  ALL_RESPONSE_LIST: 500,
  RESPONSE_LIST: 100,
  // participants
  GLOBAL_PARTICIPANTS_SEARCH: 10,
  COMPOSE_EMAIL_PARTICIPANTS_SEARCH: 10,
  PARTICIPANTS_PAGE_SEARCH: 50,
  PROJECT_FIND_AND_NOTIFY_PARTICIPANTS: 500,
}
